import React from 'react';
import styled from '@emotion/styled';

import { Layout, Wrapper } from 'components/layout';
import SEO from 'components/seo';

const StyledWrapper = styled(Wrapper)`
  min-height: 25rem;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="Contact" path="/contact/" />
    <StyledWrapper>
      <h1>Contact</h1>
      <p>You can hit me up via email at <a href="mailto:gautam@gkcgautam.io">gautam@gkcgautam.io</a></p>
    </StyledWrapper>
  </Layout>
)

export default NotFoundPage;
